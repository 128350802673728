@media (max-width: 766px) {
    .linkDetails {
        /* color: red !important; */
        font-size: 1.1rem !important;
    }
}

.hoverEffect:hover {
    background-color: #fff !important;
    color: var(--bgTheme) !important;
}

.showAnimation {
    animation: showElement .4s linear;
} 

@keyframes showElement {
    from {
        opacity: 0%;
    } 
    to {
        opacity: 100%;
    }
}

.exitAnimation {
    animation: exitElement .4s linear;
} 

@keyframes exitElement {
    from {
        opacity: 100%;
    } 
    to {
        opacity: 0%;
    }
}

@media (max-width: 766px) {

    .alertBox {
        right: -5% !important;
    }

    .alertBox1 {
        left: -132% !important;
    }
}

@media (max-width: 320px) {

    .alertBox {
        right: -4% !important;
        width: 120px !important;
        bottom: -150% !important;
    }

    .alertBox1 {
        left: -30% !important;
        width: 85px !important;
        bottom: -95% !important;
    }
}