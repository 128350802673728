.questionShimmer {
    height: 15px;
    width: 70%;
}
.lineShimmer {
    height: 15px;
    width: 70%;
}

.descriptionShimmer {
    height: 15px;
    width: 80%;
}

.optionShimmer {
    height: 30px;
    width: 70%;
}

.shimmer {
    padding: 5px;
    width: 95%;
    margin: 10px auto;
    background: #ffffff;
}

.stroke {
    background: #777;
    margin-top: 5px;
}

.shimmerWrapper {
    width: 0px;
    animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
    100% {
        width: 100%;
    }
}

.animate {
    animation: shimmer 3s;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
    background-size: 1000px 100%;
}

@keyframes shimmer {
    from {
        background-position: -1000px 0;
    }

    to {
        background-position: 1000px 0;
    }
}