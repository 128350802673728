@media (max-width: 560px) {

    .topText {
        font-size: 0.9rem !important;
    }

    .hero .topTextWrapper, .heading {
        text-align: center !important;
    }
}
@media (max-width: 991px) {
    .shortner {
        width: 100% !important;
    }
}