article{
    width: 100%;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
}
 
article .line{
    width: 100%;
    height: 20px;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    margin: 20px 0;
    border-radius: 5px;
}
 
article .shimmer{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
 
    background: linear-gradient(100deg,
    rgba(255,255,255,0) 20%,
    rgba(255,255,255,0.5) 50%,
    rgba(255,255,255,0) 80%);
 
    animation: shimmer 2s infinite linear;
}
 
@keyframes shimmer{
    from {
        transform: translateX(0%);
    }
    to{
        transform: translateX(100%);
    }
}