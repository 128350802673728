/* ================ Detail Box Component ================ */
.detailsTile {
    padding: 20px 10px;
    border-radius: 12px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.detailsTile .detailBox {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    border-right: 1px solid #eee;
}

.detailBox .icon {
    min-width: 40px;
    min-height: 40px;
    padding: 5px;
    background: #eee;
    border-radius: 125px;
    max-width: 42px;
    max-height: 42px;
}

/* ================ Detail Box Component ================ */

/* ================ Question Palette ================ */
.controlPalette {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    max-height: 55px;
    padding-bottom: 20px;
}

.controlPalette::-webkit-scrollbar {
    height: 4px;
    background-color: #eee;
}

.controlPalette::-webkit-scrollbar-thumb {
    height: 4px;
    background-color: #000;
}

.questionDot {
    background: #d6e0f5;
    min-height: 40px;
    min-width: 40px;
    font-size: 19px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    transition: .03s ease;
    position: relative;
}

.revealPendingDot {
    height: 10px;
    width: 10px;
    background: red;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 125px;
}

.controlPalette .questionDot:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.controlPalette .questionDot.active {
    background: #ffd75c;
}

.questionDot.activated {
    background: #4ac493 !important;
}

/* ================ Question Palette ================ */
/* ================ Question Preview ================ */
.widget {
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 12px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.timeAlloted {
    font-size: 65px;
}

.controlpanel {
    position: relative;
    height: 405px;
    overflow-y: scroll;
}

.controlpanel::-webkit-scrollbar {
    width: 4px;
    background-color: #eee;
}

.controlpanel::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #000;
}

img#questionWatermark {
    position: absolute;
    opacity: 0.1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -9999;
}

/* .actionsBtns,.submitBtn{
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
} */
/* ================ Question Preview ================ */
/* ================ Question Stats ================ */
.progressStats {
    position: relative;
}

.progress-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    /* background: conic-gradient(#F9E0E7 50% , #E68AA1 0); */
}

.progress-circle-fill {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
}

.attemptStats .number {
    font-size: 20px;
    font-weight: bold;
}

.attemptStats .notAnswered {
    color: #eee;
    font-size: 20px;
    font-weight: bold;
}

.statValues {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.statValues .value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
}

.statValues .colorCode {
    display: block;
    height: 18px;
    width: 25px;
}

.statValues .colorValue {
    font-size: 13px;
}

/* ================ Question Stats ================ */
/* ================ Top Performer ================ */
.topPerformer {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}

.performer {
    border: 1px solid #eee;
    padding: 20px;
    width: 180px;
    background: #eee;
    border-radius: 15px;
}

.performer .icon {
    height: 52px;
    width: 52px;
}

/* ================ Top Performer ================ */
/* ================ Leaderboard ================ */
.leaderboard .followingContainer {
    height: 275px;
    overflow: hidden;
}

.leaderboard .following {
    animation: slide 3s linear infinite;
    margin-bottom: 5px;
}

@keyframes slide {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-100%);
    }
}

.leaderboard .card {
    padding: 10px;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    transition: .3s ease;
    cursor: pointer;
    margin-bottom: 5px;
}

.leader .card {
    background-color: #4ac493;
    scale: 1.05;
    margin-bottom: 15px;
}

.leaderboard .card:hover {
    scale: 0.95;
    z-index: 999;
}

.leaderboard .card .icon {
    height: 35px;
    width: 35px;
}

.leaderboard .card .name {
    font-size: 18px;
    font-weight: 600;
}

.leaderboard .card .marks .scored {
    font-size: 18px;
    font-weight: 600;
}

.leaderboard .card .marks .total {
    font-size: 12px;
    font-weight: 300;
}

/* ================ Leaderboard ================ */
/* ================ Live Video ================ */
.liveVideoControl {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* The height of the item will now be 56.25% of the width. */
}

.liveVideoControl iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

/* ================ Live Video ================ */
/* ================ Loader ================ */

#circle {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); */
    width: 150px;
    height: 150px;
}

.loader {
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    border: 8px solid #162534;
    border-top: 8px solid #09f;
    border-radius: 50%;
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width:480px) {
    .detailsTile{
        padding: 10px;
    }
    .controlPalette{
        padding-bottom: 10px;
    }
    .controlPalette .questionDot {
        height: 30px;
        width: 30px;
        min-height: 30px;
        min-width: 30px;
        font-size: 16px;
    }

    .controlPalette .questionDot svg{
        width: 22px !important;
    }

    .controlPalette {
        max-height: 180px;
    }
}

/* ================ Loader ================ */
/* ================ Live Chat ================ */
.livechat .chatbox {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 275px;
}

.chatbox::-webkit-scrollbar {
    width: 4px;
    background-color: #eee;
}

.chatbox::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #000;
}

.livechat .chatbox .message {
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.livechat .chatbox .icon {
    display: flex;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 500;
    min-height: 30px;
    min-width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 125px;
    line-height: 13px;
}

.livechat .chatbox .details {
    background: #eee;
    padding: 8px 10px;
    margin-bottom: 6px;
    border-radius: 11px;
    border-bottom-left-radius: 0;
}

.livechat .chatbox .details .info {
    font-size: 12px;
}

.livechat .chatbox .details .time {
    font-size: 9px;
    color: #6e6e6e;
}

.livechat .chatbox .details .text {
    margin: 3px 0 0 0;
    font-size: 14px;
    word-break: break-all;
}

/* ================ Live Chat ================ */
/* ================ Splash Screen Right Courses ================ */
.rightPanelCourses .exploreBtn {
    padding: 5px 14px;
    background: linear-gradient(45deg, #cc0000, #ffa200);
    border: 1px solid #cc0000;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: 0 3px 0 #cc0000, 0 5px 10px rgba(0,0,0,0.3) !important;
}

.rightPanelCourses .exploreBtn:hover {
    box-shadow: 0 1px 0 #cc0000, 0 5px 10px rgba(0,0,0,0.3) !important;
    translate: 0 2px;
}

.rightPanelCourses .splide__pagination__page.is-active {
    background: #cc0000 !important;
    /* for active slide pagination RED */
}

/* Mobile Verification  */
#enterTest #qrWrapper img,
canvas {
    max-width: 100% !important;
}

@media (max-width: 480px) {
    #enterTest .testMarksLanguage {
        font-size: 1rem !important;
        row-gap: 0 !important;
    }

    #enterTest .scanQRLabel {
        font-size: 1.1rem !important;
    }

    #enterTest #qrWrapper {
        width: 170px !important;
    }

    #enterTest .testLogo {
        width: 200px !important;
    }

    /* ================ Question Preview ================ */
    .controlpanel {
        height: auto !important;
    }
    /* ================ Question Preview ================ */
    /* ================ Take Test Header ================ */
    .portalLogo {
        width: 70px !important;
    }

    .mobileCourseTitle {
        font-size: 1rem !important;
    }
    /* ================ Take Test Header ================ */

}

/* ================ Splash Screen Right Courses ================ */


/* .overallPerformace,.controlpanel{
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 12px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}
.overallPerformace .topHeading ,.controlpanel .topHeading{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}
.attemptStats .number{
    font-size: 20px;
    font-weight: bold;
}
.attemptStats .notAnswered{
    color: #eee;
    font-size: 20px;
    font-weight: bold;
}
.overallQuestionStats{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.progressStats{
    position: relative;
}
.progress-circle{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: conic-gradient(#F9E0E7 50% , #E68AA1 0);
}
.progress-circle-fill {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
}
.overallQuestionStats .statValues{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.overallQuestionStats .statValues .value{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
}
.overallQuestionStats .statValues .colorCode{
    display: block;
    height: 18px;
    width: 25px;
}
.overallQuestionStats .statValues .colorValue{
    font-size: 13px;
}
.leaderboard .followingContainer{
    height: 275px;
    overflow: hidden;
}
.leaderboard .following{
    animation: slide 8s linear infinite
}
@keyframes slide  {
    0% {
        transform: translateY(0%);
    } 
    100% {
        transform: translateY(-100%);
    }
}
.leaderboard .card{
    padding: 10px;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    transition: .3s ease;
    cursor: pointer;
    margin-bottom: 5px;
}
.leader .card{
    background-color: #4ac493;
    scale: 1.05;
    margin-bottom: 15px;
}
.leaderboard .card:hover{
    scale: 0.95;
    z-index: 999;
}
.leaderboard .card .icon{
    height: 35px;
    width: 35px;
}

.leaderboard .card .name{
    font-size: 18px;
    font-weight: 600;
}
.leaderboard .card .marks .scored{
    font-size: 18px;
    font-weight: 600;
}
.leaderboard .card .marks .total{
    font-size: 12px;
    font-weight: 300;
}
.detailsTile

.questionDotCover {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    flex-direction: row;
}
.detailsTile .controlPalette 
.testIsNotLive .icon {
    height: 40px;
    width: 40px;
}

.testIsNotLive {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 50px 0 0 0;
}

.testIsNotLive .info {
    font-weight: 600;
    font-size: 21px;
}

#activateQuestionPopup{
    background-color: #ddd;
    background-image: url("http://localhost:3000/lines.jpg");
    background-repeat: no-repeat;
    background-size: cover;
} */