.faq {
    user-select: none;
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
}

.faq.activeFaq .answer {
    max-height: 900px;
    animation: fade 1s ease-in-out;
}

.caret {
    transition: transform 0.5s ease-in;
}

.faq.activeFaq .caret {
    transform: rotate(180deg);
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 100%;
        transform: translateY(0px);
    }
}

@media (max-width: 768px) {
    #FAQs .doubt {
        width: 100% !important;
    }
}