.navbar {
    transition: 1s !important;
    z-index: 99 !important;
    background-color: #fff;
    transition: all 100ms linear;
}
.coloredNav {
    background: #fff;
}
.scrollNav {
    background: linear-gradient(to top, #ffffff91, #fff) !important;
    backdrop-filter: blur(5px) !important;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1) !important;
    border: none !important;
}
/* 
.navbar li>a {
    font-size: 1.2rem;
} */

/* left responsive navbar  */
.navbar .navCollapse {
    top: 0;
    left: 0;
    height: 100vh !important;
    z-index: 100;
    background-color: #fff;
    width: 300px;
    height: 60px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    /* z-index: 999 !important; */
}
.navCollapse .nav-item > .dropdown-menu {
    background-color: #fff;
    border: none;
}
/* for animation */
.navbar .visible {
    transform: translateX(0%) !important;
}

.navbar .notVisible {
    transform: translateX(-100%) !important;
}
