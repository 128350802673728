@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@200;300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

.secondaryFont {
  font-family: 'Encode Sans SC', sans-serif;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --bgTheme: #0d6efd;
  --bgThemeLow: #0d6dfd14;
  --bgThemeDark: #0041a3;
  --bs-secondary-rgb: #555555;
  --textShadow: 0 1px 6px rgba(0, 0, 0, 0.59);
  --lighterText: #eee;
  --lightest: #efeeec;
  /* --lightest: #fafafa; */
  --bannerBg: #1b2643;
  --bs-border-color: #dee2e6;
  --green: #65c466; /* Sept 18, 23 */
}

.text-primary {
  color: var(--bgTheme) !important;
}

.bg-primary {
  background-color: var(--bgTheme) !important;
}

.btn-primary {
  background-color: var(--bgTheme) !important;
}

.bg-primary-opacity {
  background-color: var(--bgThemeLow) !important;
}

/* .bg-opacity-25 {
  --bs-bg-opacity: 0.25;
} */
/* custom snippets  */
.xCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.allCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.itemsCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.xStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.titleFont {
  color: var(--bgThemeDark) !important;
  /* color: #96b9ff !important; */
  /* font-family: "Ubuntu", sans-serif; */
  font-weight: 600;
}

.textSecondary {
  color: var(--bs-secondary-rgb) !important;
}

.transition {
  transition: all 300ms ease;
}

.lighterBg {
  background-color: var(--lighterText) !important;
}

.lightestBg {
  background-color: var(--lightest) !important;
}

.scrollBarHidden {
  overflow: scroll !important;
  scrollbar-width: none !important;
}

.scrollBarHidden::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar while keeping functionality */
.scrollBarHidden {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  overflow: -moz-scrollbars-none !important; /* Firefox */
}

/* For Webkit browsers (Chrome, Safari) */
/* .element-selector::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

/* Restyle the scrollable content as needed */
/* .element-selector {
  overflow: scroll; /* Enable scrolling */

.bgTheme {
  background: var(--bgTheme) !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.extraLargeFont {
  font-size: 3.5rem !important;
}

.bgShadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 430px) {
  .extraLargeFont {
    font-size: 2.5rem !important;
  }
}

input,textarea {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
}

/* Remove the default arrow buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input,textarea:focus {
  outline: none;
}

.heading {
  font-weight: 500 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fsSm {
  font-size: 0.8rem !important;
}

#qrWrapper svg {
  width: 100%;
  height: 100%;
}

.hoverEffectPrimary:hover {
  background-color: #fff !important;
  color: var(--bgTheme) !important;
}

.hoverWhiteBtn:hover {
  background-color: var(--bgTheme) !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.inlineText {
  white-space: nowrap;
}

.gridCenter {
  height: 100%;
  display: grid;
  place-items: center;
}

/* input buttons  */
.switch {
  width: 35px;
  height: 18px;
  -webkit-appearance: none;
  background-color: #555555;
  position: relative;
  transition: all 200ms linear;
  display: inline-block;
  border-radius: 50rem;
}

.switch::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 89%;
  margin: 1px;
  top: 0;
  left: 0;
  border-radius: 1.5rem;
  background: #fff;
  transition: all 200ms linear;
}

.switch:checked:after {
  left: 44%;
}

.switch:checked {
  background-color: #00b150;
}

[data-hover-info]::after {
  content: attr(data-hover-info);
  position: absolute;
  /* white-space: nowrap; */
  width: fit-content;
  height: auto;
  bottom: 150%;
  background-color: #000;
  color: #fff;
  padding: 0px 6px;
  border-radius: 10px;
  /* opacity: 0%; */
  display: none;
  transform: translateY(-10px);
  transition: all 300ms ease;
}

[data-hover-info]::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 8px;
  bottom: 104%;
  background-color: #000;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  /* opacity: 0%; */
  display: none;
  transform: translateY(-10px);
  transition: all 300ms ease;
}

[data-hover-info]:hover::after {
  transform: translateY(0px);
  /* opacity: 100%; */
  display: inline-block;
}

[data-hover-info]:hover::before {
  transform: translateY(0px);
  /* opacity: 100%; */
  display: inline-block;
}

[data-hover-info]:hover {
  opacity: 100% !important;
}

.lighterText {
  color: #5c5c5c !important;
}

.activeTab {
  color: var(--bgTheme) !important;
  background-color: var(--lighterText) !important;
}

@media (max-width: 480px) {
  .smFullWidth {
    width: 100% !important;
  }
}

.inputField {
  color: #333;
  background: #fff !important;
  border: 1px solid rgb(183 184 186) !important;
  outline: none;
  box-sizing: border-box;
  border-radius: 7px;
  /* margin-bottom: 3px;
  padding-left: 36px;
  padding-right: 36px; */ /* Sept 19, 23 to fix extra padding */
  /* Sept 19, 23 to give actual padding ===========  */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 7px;
  /* Sept 19, 23 to give actual padding ===========  */
}

.inputField input {
  width: 100%;
  padding: 0 !important;
  border-left: 1px solid;
  padding-left: 8px !important;
}

.inputField .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  margin-right: 5px;
  /* color: var(--bgTheme); */
}

.lightWhiteBg {
  background-color: #f3f3f3;
}

.widthFit {
  width: fit-content;
}

.colorCodeWidth {
  width: 128px;
}

/* for prompt url component  */

/* Sept 18, 23 while working on UI */

.bgGreen {
  background-color: var(--green);
}

/* Sept 18, 23 while working on UI */

.tilesHoverEffect:hover {
  background-color: #fff !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1) !important;
}

.fw500 {
  font-weight: 500 !important;
}

/* Sept 19, 23 while working on UI */
/* Sept 20, 23 while working on UI */
.toggleRotate {
  transform: rotate(180deg) translateY(-3px);
}

.cubicTransition {
  transition: all 100ms cubic-bezier(0.23, 1, 0.320, 1);
}

.smallBox {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
}
/* Sept 20, 23 while working on UI */
.editFormBtn {
  background-color: #eeeeee;
  color: #929292;
  border: none;
}
.editFormBtn:hover {
  background-color: var(--bgTheme);
  color: #fff;
}

@media (max-width: 480px) {
  .smFullWidth {
    width: 100% !important;
  }
}

@media (max-width: 384px) {
  .smResponsiveAlias {
    width: 144px !important;
  }
}