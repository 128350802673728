.analyticsIcon div {
    width: 10px;
    height: 10px;
    border-radius: 300rem;
}

@media (max-width: 720px) {
    .doughnutChartComponent canvas {
        width: 100% !important;
    }
}