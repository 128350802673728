:root {
    --colorPrimary: var(--bgTheme);
    --colorSecondary: #00ffdd;
    --fieldColor: #ffffff;
    --iconColor: #000;
    --btnTextColor: #FFFFFF;
}

.colorCustomizations .selectColorType {
    user-select: none;
}

.gradientText {
    background: linear-gradient(to right, var(--colorPrimary), var(--colorSecondary));
    background-clip: text;
    -webkit-background-clip: text; /* For Safari */
    -webkit-text-fill-color: transparent;
    color: transparent; /* Fallback for unsupported browsers */
}
.gradientTextPredefined {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}

@media (max-width: 766px) {
    .widthResponsive {
        width: 50% !important;
    }
}

@media (max-width: 560px) {
    .widthResponsive {
        width: 100% !important;
    }
}

@media (max-width: 400px) {
    .colorCodeResponsive {
        width: 100px !important;
    }
    .colorChoose {
        width: 60px !important;
    }
}