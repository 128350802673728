.loaderText {
    user-select: none;
    /* font-family: 'Pacifico', cursive !important; */
    font-weight: 500 !important;
    font-size: 12rem;
    color: transparent;
    -webkit-text-stroke: 2px var(--bgTheme);
    background: url("../../../public/img/wave.png") no-repeat;
    background-size: 100% 100%;
    background-clip: text;
    /* background-position-y: 250px;
    background-position-x: 14px; */
    animation: animate 2s linear infinite;
}
@keyframes animate {
    0% {
        background-position-y: 250px;
        background-position-x: 10px;
    }
    30% {
        background-position-y: 172px;
        background-position-x: 10px;
    }
    60% {
        background-position-y: 107px;
        background-position-x: -5px;
    }
    100% {
        background-position-y: 0;
        background-position-x: 10px;
    }
}
@media (max-width: 576px) {
    .loaderText {
        font-size: 6rem;
        -webkit-text-stroke: 1px var(--bgTheme);
        animation: animate 2s linear infinite;
    }
    @keyframes animate {
        0% {
            background-position-y: 172px;
            background-position-x: 5px;
        }
        30% {
            background-position-y: 172px;
            background-position-x: 5px;
        }
        60% {
            background-position-y: 107px;
            background-position-x: -5px;
        }
        100% {
            background-position-y: 0;
            background-position-x: 5px;
        }
    }
}