@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@200;300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

.secondaryFont {
  font-family: 'Encode Sans SC', sans-serif;
}

@keyframes blink {
	0% { opacity: 0.1; }
	50% { opacity: 1; }
	100% { opacity: 0.1; }
}

.blinking-text {
	animation: blink 1s infinite;
}
.datatable-table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid #eee;
}
.datatable-table tbody tr{
  word-break: break-all;
}
.backgroundMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1111;
  background-color: rgba(0, 0, 0, 0.4);
  /* -webkit-backdrop-filter: blur(1px); */
  backdrop-filter: blur(1px);
  /* transition: 3s ease-in; */
}
.loadingAlert {
  z-index: 1112;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 60px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.content-element {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.content-element.loaded {
  opacity: 1;
}