.fullQuestionPreview{
    /* margin-top: 50px; */
    padding: 30px 20px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.queNumber,.queMarks{
    font-size: 19px;
    font-weight: 500;
}

.question{
    font-size: 24px;
    font-weight: 600;
}

.options{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin: 20px 0 0 0;
}
.option{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    border: 1px solid #eee;
    padding: 10px 20px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}
.option:hover{
    background-color: #ddd;
}
.option.active{
    background-color: rgb(74, 196, 147,0.5);
    border-color: rgb(74, 196, 147);
}
.option[data-correct]{
    background-color: rgb(74, 196, 147);
    border-color: rgb(74, 196, 147);
}
.option.correct{
    background-color: rgb(74, 196, 147);
    border-color: rgb(74, 196, 147);
}
.option.incorrect{
    background-color: rgb(249, 122, 131,0.5);
    border-color: rgb(249, 122, 131);
}