#waveLoaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

#waveLoaderContainer div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}

#waveLoaderContainer span {
    width: 10px;
    height: 140px;
    border-radius: 100rem;
    /* margin: 0 1rem; */
    animation: wave 1s linear infinite;
}

@keyframes wave {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

#waveLoaderContainer div span:nth-child(2) {
    animation-delay: 0.1s;
}

#waveLoaderContainer div span:nth-child(3) {
    animation-delay: 0.2s;
}

#waveLoaderContainer div span:nth-child(4) {
    animation-delay: 0.3s;
}

#waveLoaderContainer div span:nth-child(5) {
    animation-delay: 0.4s;
}

#waveLoaderContainer div span:nth-child(6) {
    animation-delay: 0.5s;
}

#waveLoaderContainer div span:nth-child(7) {
    animation-delay: 0.6s;
}

#waveLoaderContainer div span:nth-child(8) {
    animation-delay: 0.7s;
}

#waveLoaderContainer div span:nth-child(9) {
    animation-delay: 0.8s;
}

#waveLoaderContainer div span:nth-child(10) {
    animation-delay: 0.9s;
}