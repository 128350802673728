/* #alertCtaCover{
    position: fixed;
    top: 9%;
    z-index: 99999999999999;
} */
.alertCta{
    background-color: #cfe3ff;
    border-radius: 7px;
    border: 1px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 12px 15px;
}
.alertCta .logo{
    width: 120px;
}

.alertCta .ctaLink{
    display: flex;
    align-items: center;
    justify-content: center;
}
