.underlineEffect::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    top: 80%;
    left: 0;
    background: #fff;
    transition: width 300ms ease;
}

.underlineEffect:hover::after {
    width: 100%;
}

.jump:active {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
}

.jump:hover .dropdown {
    max-height: 700px !important;
}

.jump:hover .caret {
    /* transform-origin: 50% 50%; */
    transform: rotate(180deg);
}

@media (max-width: 380px) {
    .jump {
        font-size: .9rem;
    }
}